@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'fonts';

@layer base {
	:root {
		/* Container Colors */
		--container-bg-primary: #000000;
		--container-bg-secondary: #0f0f0f;
		--container-bg-tertiary: #1c1c1c;
		--container-bg-accent: #2d334a;
		--container-bg-accent2: #cb9552;
		--container-bg-accent3: #3765ad;
		--container-color-secondary: #cb9552;
		--container-color-accent: #000000;
		--container-color-primary: #fff;

		/* Button Colors */
		--button-primary: #72b4ab;
		--button-danger: #ff0000;
		--button-secondary: #ffff00;
		/* Add button-danger and button-secondary if you have specific colors */

		/* Navbar Colors */
		--navbar-bg-primary: #242629;
		--navbar-color-secondary: #00121d;
		--navbar-color-primary: #fff;
		--navbar-wallet-color: #929292;

		/* Footer Colors */
		--footer-bg-primary: #242629;
		--footer-color-secondary: #94a1b2;
		--footer-color-primary: #fff;

		/* for gradient */
		--primary-gradient-static-color-s: #000000;
	}
}

html,
body {
	scroll-behavior: smooth;
	position: relative;
}

ul {
	@apply list-disc pl-5;
}

ol {
	@apply list-decimal pl-5;
}

li {
	@apply mb-2;
}

@layer components {
	.sidebar {
		background: #00111d;
	}
	.sidebar-header {
		background: #00111d;
	}
	.active-sidebar {
		background: rgba(255, 255, 255, 0.2);
		box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
		border-radius: 0px;
	}
	.disabled {
		pointer-events: none;
	}
	/* Currenccy searchmodal */
	.switch {
		position: relative;
		display: inline-block;
		width: 34px;
		height: 20px;
	}

	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	.slider:before {
		position: absolute;
		content: '';
		height: 12px;
		width: 12px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	input:checked + .slider {
		background-color: #33ff00;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #33ff00;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(12px);
		-ms-transform: translateX(12px);
		transform: translateX(12px);
	}

	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
	.header-container {
		@apply max-w-full mx-auto  px-2 sm:px-6 lg:px-32;
	}
	/* py-6 */
	.header-container h1 {
		@apply text-3xl font-bold;
	}
	// .content-container {
	//   @apply mx-auto py-0 px-2 sm:px-8 lg:px-32;
	// }
	.footer-heading-1 {
		@apply text-2xl font-bold pt-2 pb-2;
	}
	.footer-heading-2 {
		@apply text-xl font-bold pt-2 pb-2;
	}
	.footer-logobox {
		@apply mt-5 pb-5 border border-gray-200 rounded-md;
	}
	// .btn {
	//   @apply border p-1 rounded-2xl w-5/12 text-gray-100 hover:text-white;
	// }
	.user-menu-border {
		@apply sm:mt-10 sm:mx-10 mt-2 mx-2;
	}
	.sec-user-menu-border {
		@apply sm:mx-10 mx-2 mb-2 sm:mb-10;
	}
	.user-menu-h1 {
		@apply text-xs sm:text-lg md:text-2xl font-bold uppercase;
	}
	.user-menu-h2 {
		@apply text-xs sm:text-sm md:text-lg font-bold uppercase;
	}
	.user-menu-h1-container {
		@apply shadow-md p-5;
	}
	.normal-text {
		@apply text-xs sm:text-sm md:text-base;
	}
	.accent-text {
		@apply text-sm sm:text-lg md:text-lg font-semibold;
	}
	.active {
		@apply border-indigo-500 text-gray-100;
	}
}
// :root {
//   --background: theme("colors.black-rock");
//   --background-footer: theme("colors.scarlet-gum");
//   --background-balance: theme("colors.gray.100");
//   --header-bg: theme("colors.cloud-burst");
//   --header-text: theme("colors.gray.300");
//   --menu-hover: theme("colors.ebony-900");
//   --header-text-active: theme("colors.gray.100");
//   --navbar-bg: theme("colors.black-rock");
//   --navbar-text: theme("colors.gray.200");
//   --navbar-icons: theme("colors.chetwode-blue");
//   --borders: theme("colors.down-river");
//   --tiles: theme("colors.black-rock");
//   --card-bg: theme("colors.black-pearl");
//   --card-text: theme("colors.gray.200");
//   --footer-text: theme("colors.white");
//   --footer-bg-light: theme("colors.scarlet-gum");
//   --footer-bg-dark: theme("colors.black-rock");
//   --main-text-h1: theme("colors.gray.100");
//   --main-text-h2: theme("colors.gray.200");
//   --main-text: theme("colors.gray.300");
//   --accent-dark: theme("colors.fuchsia.900");
//   --primary-dark: theme("colors.gray.300");
//   --primary-medium: theme("colors.gray.200");
//   --primary-light: theme("colors.gray.100");
//   --text-negative: theme("colors.gray.800");
//   --background-negative: theme("colors.gray.100");

//   --swiper-pagination-color: white;
//   --swiper-pagination-bullet-inactive-color: rgba(255, 255, 255, 0.9);
//   --swiper-pagination-bullet-width: 0.31rem;
//   --swiper-pagination-bullet-width: 2rem;
// }
/* [data-theme="copa"] {
  --background: theme("colors.black-rock");
  --background-footer: theme("colors.scarlet-gum");
  --header-bg: theme("colors.cloud-burst");
  --header-text: theme("colors.gray.300");
  --menu-hover: theme("colors.ebony-900");
  --header-text-active: theme("colors.gray.100");
  --navbar-bg: theme("colors.black-rock");
  --navbar-text: theme("colors.gray.200");
  --navbar-icons: theme("colors.chetwode-blue");
  --borders: theme("colors.down-river");
  --tiles: theme("colors.black-rock");
  --card-bg: theme("colors.black-pearl");
  --card-text: theme("colors.gray.200");
  --footer-text: theme("colors.white");
  --footer-bg-light: theme("colors.scarlet-gum");
  --footer-bg-dark: theme("colors.black-rock");
  --main-text-h1: theme("colors.gray.100");
  --main-text-h2: theme("colors.gray.200");
  --main-text: theme("colors.gray.300");

  --accent-dark: theme("colors.fuchsia.900");
  --primary-dark: theme("colors.gray.300");
  --primary-medium: theme("colors.gray.200");
  --primary-light: theme("colors.gray.100");
} */
[data-theme='new'] {
	--background: theme('colors.ebony-900');
	--background-footer: theme('colors.ebony-600');
	--background-balance: theme('colors.pink.800');
	--header-bg: theme('colors.ebony-700');
	--header-text: theme('colors.gray.300');
	--menu-hover: theme('colors.gray.100');
	--header-text-active: theme('colors.gray.100');
	--navbar-bg: theme('colors.ebony-600');
	--navbar-text: theme('colors.gray.400');
	--navbar-icons: theme('colors.gray.600');
	--borders: theme('colors.gray.600');
	--tiles: theme('colors.ebony-700');
	--card-bg: theme('colors.ebony-800');
	--card-text: theme('colors.gray.200');
	--footer-text: theme('colors.white');
	--footer-bg-light: theme('colors.ebony-600');
	--footer-bg-dark: theme('colors.ebony-900');
	--main-text-h1: theme('colors.gray.100');
	--main-text-h2: theme('colors.gray.200');
	--main-text: theme('colors.gray.300');
	--accent-dark: theme('colors.fuchsia.900');
	--primary-dark: theme('colors.gray.300');
	--primary-medium: theme('colors.gray.200');
	--primary-light: theme('colors.gray.100');
	--text-negative: theme('colors.gray.800');
	--background-negative: theme('colors.gray.100');
}

// span.swiper-pagination-bullet.swiper-pagination-bullet-active {
//   background-color: white;
//   opacity: 1;
// }

// span.swiper-pagination-bullet {
//   width: 1rem;
//   border-radius: 3.125rem;
//   height: 0.113rem;
//   margin-right: 0.194rem !important;
// }

// .swiper-pagination-bullets.swiper-pagination-horizontal {
//   bottom: 0.5rem !important;
//   left: 1.125rem !important;
//   width: fit-content !important;
// }

@media (min-width: 768px) {
	// span.swiper-pagination-bullet {
	//   width: 3.375rem;
	//   height: 0.25rem;
	//   margin-right: 0.4rem !important;
	// }

	// .swiper-pagination-bullets.swiper-pagination-horizontal {
	//   bottom: 1rem !important;
	//   left: 2.813rem !important;
	//   width: fit-content !important;
	// }
}

@media (min-width: 900px) {
	// span.swiper-pagination-bullet {
	//   width: 5.857rem;
	//   height: 0.25rem;
	//   margin-right: 0.465rem !important;
	// }

	// .swiper-pagination-bullets.swiper-pagination-horizontal {
	//   bottom: 1.625rem !important;
	//   left: 2.813rem !important;
	//   width: fit-content !important;
	// }
}

// @responsive {
//   .text-shadow {
//     text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   }

//   .text-shadow-md {
//     text-shadow: 1px 1px 2px black, 0 0 0.05em black, 0 0 0.05em black;
//   }

//   .text-shadow-lg {
//     text-shadow: 1px 1px 2px black, 0 0 0.1em black, 0 0 0.2em black;
//   }

//   .text-shadow-none {
//     text-shadow: none;
//   }

//   .text-shadow-yellow {
//     text-shadow: 0px 0px 5px yellow;
//   }
// }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border: 1px solid #25334100;
	-webkit-text-fill-color: white;
	-webkit-box-shadow: 0 0 0px 1000px #25334100 inset;
	box-shadow: 0 0 0px 1000px #25334100 inset;
	transition: background-color 5000s ease-in-out 0s;
	color: white;
}

input:focus,
select:focus {
	outline: none;
}

.form-control {
	background-color: transparent;
	border: none;
	color: #fff;
	width: 100%;
}

.form-control:focus {
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
		var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
		var(--tw-ring-color);
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

input.form-control {
	@apply mobile-xl:text-[1.125rem] text-[0.75rem];
}

#playgame:-webkit-full-screen {
	width: 100%;
	height: 100%;
}

.scrollbar-hide::-webkit-scrollbar {
	display: none;
}
.scrollbar-hide {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.gameCard {
	@apply relative overflow-y-clip z-20;
	&:hover,
	&:active {
		.gameCardHoveredEl {
			transform: translateY(0);
			opacity: 1;
			pointer-events: unset;
		}
	}
	.gameCardHoveredEl {
		opacity: 0;
		pointer-events: none;
	}
	.container-image {
		@apply relative pb-4 bg-gray-500 rounded-lg h-full w-full;
		&::before {
			content: '';
			@apply absolute animate-pulse top-0 left-0 w-full h-full bg-gray-700 text-2xl font-bold z-0 rounded-lg flex justify-center items-center pointer-events-none;
		}
		img {
			@apply relative z-10;
		}
	}
	.title {
		@apply w-full h-full absolute bottom-0 flex justify-center items-end p-2 rounded-lg pointer-events-none cursor-default z-30;
	}
}

.recommended-width {
	aspect-ratio: 2/1;
	min-width: calc(100%);
}

.recommended-width-3 {
	min-width: calc(100%);
}

.provider-width {
	aspect-ratio: 2/1;
	min-width: calc(50% - 0.5rem); //2 KARTU
}

.home-game-section1 {
	@apply grid-cols-[repeat(10,minmax(calc(100%/2.1),1fr))] mobile-l:grid-cols-[repeat(10,minmax(calc(100%/3.225),1fr))] sm:grid-cols-[repeat(10,minmax(calc(100%/4.35),1fr))] md:grid-cols-[repeat(10,minmax(calc(100%/4.28),1fr))] lg:grid-cols-[repeat(10,minmax(calc(100%/6.53),1fr))] xl:grid-cols-[repeat(10,minmax(calc(100%/8.79),1fr))];
}

.home-game-section2 {
	@apply grid-cols-[repeat(10,minmax(calc(100%/1),1fr))] mobile-xl:grid-cols-[repeat(10,minmax(calc(100%/2.075),1fr))] sm:grid-cols-[repeat(10,minmax(calc(100%/2.05),1fr))] lg:grid-cols-[repeat(10,minmax(calc(100%/3.1),1fr))] xl:grid-cols-[repeat(10,minmax(calc(100%/4.165),1fr))];
}

.home-game-section3 {
	@apply grid-cols-[repeat(10,minmax(calc(100%/2.075),1fr))] md:grid-cols-[repeat(10,minmax(calc(100%/3.135),1fr))] lg:grid-cols-[repeat(10,minmax(calc(100%/4.2),1fr))] xl:grid-cols-[repeat(10,minmax(calc(100%/6.415),1fr))];
}

.game-grid {
	@apply grid-cols-[repeat(2,minmax(8rem,1fr))] sm:grid-cols-[repeat(4,minmax(8rem,1fr))] lg:grid-cols-[repeat(6,minmax(8rem,1fr))] xl:grid-cols-[repeat(8,minmax(8rem,1fr))];
}

.card-width {
	aspect-ratio: 6/7;
}

.myContainer {
	width: 100%;
	padding: 0 16px;
}

.sportAspect {
	aspect-ratio: 5/3;
}

.aspekper {
	aspect-ratio: 2/1;
}

.aspekPromo {
	aspect-ratio: 3/1;
}

.footerIcon {
	aspect-ratio: 2/1;
}

.logoIcon {
	aspect-ratio: 3 / 1;
}

.articleAspect {
	aspect-ratio: 5/6;
}

.aspectLogo {
	aspect-ratio: 3 / 1;
}

.aspectWallet {
	aspect-ratio: 3 / 1;
}

body {
	background: var(--primary-color-s);
}

/* container header */

.container-header {
	@apply px-2 leading-none;
}

.container-body {
	@apply px-2 leading-none xl:max-w-7xl;
	// background: var(--primary-color-s);
}

.container-footer {
	@apply p-2 xl:max-w-7xl;
}

// custom class

.no-scroll {
	overflow: hidden;
}

.inherit-bg {
	background-color: inherit;
}

.primary-background {
	background: var(--primary-color-s);
}

.primary-gradient-background {
	background: var(--primary-gradient-color-s);
	color: var(--primary-color-s);
}

.secondary-gradient-background {
	background: var(--primary-gradient-color-s);
}

.tertiary-gradient-background {
	background: var(--primary-gradient-color-s);
}

.gradient-text-color {
	background: var(--primary-gradient-color-s);
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
}

.icon-gradient-text-color {
	background: var(--icon-gradient-color-s);
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
}

.gradient-button {
	background: var(--primary-gradient-color-s);

	.gradient-border-button {
		position: relative;
		// background: var(--container-bg-primary); // Main background color
		background: var(--container-bg-primary); // Main background color
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 2px; // Add padding to create the inset effect

		&::before {
			content: '';
			position: absolute;
			top: 0; // Align with the border edge
			left: 0; // Align with the border edge
			right: 0; // Align with the border edge
			bottom: 0; // Align with the border edge
			background: var(--primary-gradient-color-s);
			border-radius: inherit; // Maintains the border-radius of the parent
			z-index: -1;
			margin: 2px; // This margin creates the inset effect
		}

		background-clip: content-box; // Clip background to content box

		.button-text-color {
			background: var(--primary-gradient-color-s);
			-webkit-background-clip: text;
			background-clip: text;
			color: transparent;
		}
	}

	.gradient-full-button {
		background: var(--primary-gradient-color-s);

		.button-text-color {
			color: var(--primary-color-s);
		}
	}
}

.button-gradient_border__text {
	background: var(--primary-gradient-color-s);
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
}

.button-gradient_full__text {
	color: var(--primary-color-s);
}

.button-gradient_border {
	background: padding-box linear-gradient(var(--container-bg-primary), var(--container-bg-primary)),
		border-box var(--primary-gradient-color-s);
}

.button-gradient_full {
	background: border-box var(--primary-gradient-color-s);
}

.gradient-lottery-card {
	background: var(--primary-gradient-color-s);

	.text-lottery-title {
		background: var(--primary-gradient-color-s);
		color: var(--primary-color-s);
	}

	.gradient-border-lottery-card {
		position: relative;
		background: var(--container-bg-primary); // Main background color
		// background: var(--primary-color-s); // Main background color
		padding: 1px; // Add padding to create the inset effect

		&::before {
			content: '';
			position: absolute;
			top: 0; // Align with the border edge
			left: 0; // Align with the border edge
			right: 0; // Align with the border edge
			bottom: 0; // Align with the border edge
			background: var(--primary-gradient-color-s);
			border-radius: inherit; // Maintains the border-radius of the parent
			z-index: -1;
			margin: 2px; // This margin creates the inset effect
		}

		background-clip: content-box; // Clip background to content box
	}
}

.gradient-border {
	position: relative;
	// This should be 'inherit' to take the parent's background color
	background: inherit;
	display: flex;
	align-items: center;
	justify-content: center;

	&::before {
		content: '';
		position: absolute;
		top: -3px; // Should match your border thickness
		left: -3px; // Should match your border thickness
		right: -3px; // Should match your border thickness
		bottom: -3px; // Should match your border thickness
		z-index: -1;
		background: var(--primary-gradient-color-s);
		border-radius: inherit; // Maintains the border-radius of the parent
	}

	// Ensures that the background extends only to the border area
	background-clip: padding-box;
}

.game-search-input-wrapper {
	padding: 2px; // Adjust for border thickness
	background: var(--primary-gradient-color-s); // Gradient colors
	border-radius: inherit; // Adjust as needed

	.game-search-text-color {
		background: var(--primary-gradient-color-s);
		-webkit-background-clip: text;
		background-clip: text;
		color: transparent;
	}
}

.swiper-wrapper {
	transition-timing-function: linear;
}

// .swiper-slide {
//   width: 30%;
// }

// .swiper-container-free-mode > .swiper-wrapper{
//   transition-timing-function : linear;
// }

@media (min-width: 375px) {
	.sportAspect {
		aspect-ratio: 6/3;
	}
	.recommended-width {
		aspect-ratio: 5/3;
		min-width: calc(50% - 0.5rem);
	}

	.card-width-3 {
		min-width: calc(50% - 0.5rem); //2 KARTU
	}
}

@media (min-width: 530px) {
	.myContainer {
		max-width: 530px;
	}
	.card-width {
		min-width: calc(25% - 0.75rem); //4 KARTU
	}
	.card-width-2 {
		min-width: calc(50% - 0.5rem); //2 KARTU
	}
	.recommended-width-3 {
		min-width: calc(50% - 0.5rem); //2 KARTU
	}
	.provider-width {
		min-width: calc(25% - 0.75rem); //4 KARTU
	}
	.sportAspect {
		aspect-ratio: 6/3;
	}
}
@media (min-width: 640px) {
	.myContainer {
		max-width: 640px;
	}
	.sportAspect {
		aspect-ratio: 6/4;
	}
}
@media (min-width: 768px) {
	.myContainer {
		max-width: 768px;
	}
	.card-width-3 {
		min-width: calc(33.33333333% - 0.6666666667rem); //3 KARTU
	}
	// .recommended-width-3 {
	//   min-width: calc(33.33333333% - 0.6666666667rem);//3 KARTU
	// }
}

@media (min-width: 1024px) {
	.myContainer {
		max-width: 1024px;
	}
	.card-width {
		min-width: calc(16.6667% - 0.8333333333rem); //6 KARTU
	}
	.card-width-2 {
		min-width: calc(33.33333333% - 0.6666666667rem); //3 KARTU
	}
	.card-width-3 {
		min-width: calc(25% - 0.75rem); //4 KARTU
	}
	.provider-width {
		min-width: calc(16.6667% - 0.8333333333rem); //6 KARTU
	}
	.recommended-width {
		min-width: calc(33.33333333% - 0.6666666667rem); //3 KARTU
	}
	.recommended-width-3 {
		min-width: calc(50% - 0.5rem); //2 KARTU
	}
	.sportAspect {
		aspect-ratio: 6/3;
	}
}
@media (min-width: 1280px) {
	.myContainer {
		max-width: 1280px;
	}
	.card-width {
		min-width: calc(12.5% - 0.875rem); //8 KARTU
	}
	.card-width-2 {
		min-width: calc(25% - 0.75rem); //4 KARTU
	}
	.card-width-3 {
		min-width: calc(16.6667% - 0.8333333333rem); //6 KARTU
	}
	.provider-width {
		min-width: calc(12.5% - 0.875rem); //8 KARTU
	}
	.recommended-width {
		min-width: calc(25% - 0.75rem);
	}
	.recommended-width-3 {
		min-width: calc(33.33333333% - 0.6666666667rem); //3 KARTU
	}
	.sportAspect {
		aspect-ratio: 3/1;
	}
}

.shadowCard {
	box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.56);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

::-webkit-calendar-picker-indicator {
	filter: invert(1);
}

.blue-circle {
	animation-delay: 0.1s;
}
.green-circle {
	animation-delay: 0.2s;
}
.red-circle {
	animation-delay: 0.3s;
}

.scroll-container {
	display: flex;
	gap: 2rem; // Adjust the gap as needed
	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch; // For momentum scrolling on iOS devices
	scrollbar-width: none; // For Firefox
	&::-webkit-scrollbar {
		display: none; // For Chrome, Safari, and newer Edge versions
	}

	.card {
		flex: 0 0 auto; // Flexbox settings for the cards
		width: 200px; // Adjust the width as needed
		// Add other styling for your cards
	}
}



//Modal for crypto wallet

.modal-crypto {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.modal-crypto::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}


@media only screen and (max-width: 1024px) {
	.modal-crypto{
		height: 40%;
		width: auto;
	}

	.crypto-close-icon{
		height: 12px;
	}
}